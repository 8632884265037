import React from "react";
// import resumeFile from "../documents/resume.pdf";

const Resume = ({ classicHeader, darkTheme }) => {
  const educationDetails = [
    {
      yearRange: "2024",
      title1: "JavaScript Pro: Mastering Advanced Concepts and Techniques",
      link1: "https://www.udemy.com/course/pro-javascript",
      place1: "Udemy",
      desc1:
        "OOP Design patterns & principles, functional programming, scope & closures, timers, browser storage APIs",
      title2: "Mastering Material UI",
      link2:
        "https://www.udemy.com/course/mastering-mui-component-customization",
      place2: "Udemy",
      desc2:
        "Global theme customization with createTheme() & ThemeProvider; layout & styling with the sx and component props; reusable styles with styled(); modifying default styles using the component classes object",
    },
    {
      yearRange: "2023",
      title1: "React, NodeJS, Express & Mongo - The MERN Stack",
      link1:
        "https://www.udemy.com/course/react-nodejs-express-mongodb-the-mern-fullstack-guide/",
      place1: "Udemy",
      desc1:
        "Full stack React with NodeJS, Express & Mongo including authentication and deployment",
      title2: "Node with React: Fullstack Web Development",
      link2:
        "https://www.udemy.com/course/node-with-react-fullstack-web-development/",
      place2: "Udemy",
      desc2:
        "Additional MERN stack study including OAuth, routing in development & production environments, and proxy server",
      title3: "Mastering Typescript",
      link3: "https://www.udemy.com/course/learn-typescript/",
      place3: "Udemy",
      desc3:
        "Extensive Typescript course including types, generics, aliases & interfaces, types with classes, and Typescript with React",
      title4: "JavaScript Unit Testing - The Practical Guide",
      link4:
        "https://www.udemy.com/course/javascript-unit-testing-the-practical-guide/",
      place4: "Udemy",
      desc4: "Automated unit testing with Jest & Vitest",
      title5: "Master the Coding Interview: Data Structures and Algorithms",
      link5:
        "https://www.udemy.com/course/master-the-coding-interview-data-structures-algorithms/",
      place5: "Udemy",
      desc5:
        "Big-O notation; data structures including arrays, hash tables, linked lists, stacks & queues, trees and graphs; recursion; sorting, search and traversal algorithms; and dynamic programming",
    },
    {
      yearRange: "2022",
      title1: "The Modern React Bootcamp",
      link1: "https://www.udemy.com/course/modern-react-bootcamp/",
      place1: "Udemy",
      desc1:
        "Comprehensive React course including class and function-based components, React hooks, state management, context and React Router",
      title2: "React - The Complete Guide",
      link2:
        "https://www.udemy.com/course/react-the-complete-guide-incl-redux/",
      place2: "Udemy",
      desc2:
        "Additional React study including component styling; advanced hooks with context, reducers & memoization; React Router loaders & actions; and Redux with React-Redux & Redux-Toolkit",
    },
    {
      yearRange: "2021",
      title1: "Complete SEO Training",
      link1:
        "https://www.udemy.com/course/search-engine-optimization-for-beginners-seo-that-works/",
      place1: "Udemy",
      desc1:
        "Title, keyword, desription, heading, and meta-tag optimization; robots.txt and sitemap.xml files; Google Search Console, page ranking, link analysis and backlink building",
    },
    {
      yearRange: "2020",
      title1: "The Web Developer Bootcamp",
      link1: "https://www.udemy.com/course/the-web-developer-bootcamp/",
      place1: "Udemy",
      desc1:
        "Full stack web development fundamentals including HTML, CSS, JavaScript, DOM, Node, Express, EJS, MongoDB, Mongoose and Passport",
      title2: "The Modern JavaScript Bootcamp Course",
      link2:
        "https://www.udemy.com/course/javascript-beginners-complete-tutorial/",
      place2: "Udemy",
      desc2:
        "Additional JavaScript topics including promises & async/await, classes & OOP, web API's, and rest, spread & destructuring",
    },
  ];

  const experienceDetails = [
    {
      title: "HTML & CSS/SCSS",
      detail: "with Grid & Flexbox",
    },
    {
      title: "Bootstrap 5, React-Bootstrap, Material UI, Semantic UI",
      detail: "",
    },
    {
      title: "Javascript ES6+",
      detail: "",
    },
    {
      title: "Typescript",
      detail: "",
    },
    {
      title: "Jquery",
      detail: "",
    },
    {
      title: "React",
      detail: "with class & functional components",
    },
    {
      title: "Advanced React Hooks",
      detail: "with context, reducers & memoization",
    },
    {
      title: "React Router",
      detail: "with loaders & actions",
    },
    {
      title: "Redux",
      detail: "with middleware, React-Redux & Redux-Toolkit",
    },
    {
      title: "NodeJS & NPM",
      detail: "",
    },
    {
      title: "ExpressJS",
      detail: "with middleware, async error handling, router & sessions",
    },
    {
      title: "MongoDB, Mongoose",
      detail: "",
    },
    {
      title: "SQL, MySQL, PostgreSQL",
      detail: "",
    },
    {
      title: "JWT, Oauth & session-based authentication",
      detail: "with Passport, bcrypt & jsonwebtoken",
    },
    {
      title: "Fullstack MERN development",
      detail: "with proxy server",
    },
    {
      title: "Fullstack MERN authentication",
      detail: "with JWT & refresh tokens",
    },
    {
      title: "Fullstack MERN deployment",
      detail: "with single & multiple servers",
    },
    {
      title: "API integrations",
      detail:
        "including automated email generation using Agenda, CRON & Sendgrid",
    },
    {
      title: "Sendgrid API, Stripe API",
      detail: "",
    },
    {
      title: "Web APIs",
      detail: "",
    },
    {
      title: "Application security",
      detail: "with Helmet, Joi and input-sanitization",
    },
    {
      title: "Application deployment & maintenance",
      detail: "with Heroku",
    },
    {
      title: "Automated testing",
      detail: "with Jest, Vitest & Testing-Library",
    },
    {
      title: "Git & Github",
      detail: "",
    },
    {
      title: "Linux command line",
      detail: "",
    },
    {
      title: "Google Analytics",
      detail: "implementation, monitoring and analysis",
    },
    {
      title: "Google Ads",
      detail: "",
    },
  ];

  const foundations = [
    {
      title: "Data structures and algorithms",
    },
    {
      title: "OOP design patterns and principles",
    },
    {
      title: "Modular programming",
    },
    {
      title: "Code scalability",
    },
    {
      title: "Agile Development",
    },
    {
      title: "Scrum & Kanban",
    },
  ];

  // const skills = [
  //   {
  //     name: "Web Design",
  //     percent: 65,
  //   },
  //   {
  //     name: "HTML/CSS",
  //     percent: 95,
  //   },
  //   {
  //     name: "JavaScript",
  //     percent: 80,
  //   },
  //   {
  //     name: "React JS",
  //     percent: 70,
  //   },
  //   {
  //     name: "Angular Js",
  //     percent: 60,
  //   },
  //   {
  //     name: "Bootstrap",
  //     percent: 99,
  //   },
  // ];

  return (
    <section
      id="resume"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Summary
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Resume
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gx-5">
          {/* My Education */}
          <div className="col-md-6">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              Education
            </h2>
            {educationDetails.length > 0 &&
              educationDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <a href={value.link1} target="_blank" rel="noreferrer">
                    <h3
                      className={
                        "text-5 school-link " + (darkTheme ? "text-white" : "")
                      }
                    >
                      {value.title1}
                    </h3>
                  </a>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {value.place1}
                  </p>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {value.desc1}
                  </p>
                  {value.desc2 && (
                    <div>
                      <a href={value.link2} target="_blank" rel="noreferrer">
                        <h3
                          className={
                            "text-5 pt-3 school-link " +
                            (darkTheme ? "text-white" : "")
                          }
                        >
                          {value.title2}
                        </h3>
                      </a>
                      <p className={darkTheme ? "text-primary" : "text-danger"}>
                        {value.place2}
                      </p>
                      <p
                        className={"mb-0 " + (darkTheme ? "text-white-50" : "")}
                      >
                        {value.desc2}
                      </p>
                    </div>
                  )}
                  {value.desc3 && (
                    <div>
                      <a href={value.link3} target="_blank" rel="noreferrer">
                        <h3
                          className={
                            "text-5 pt-3 school-link " +
                            (darkTheme ? "text-white" : "")
                          }
                        >
                          {value.title3}
                        </h3>
                      </a>
                      <p className={darkTheme ? "text-primary" : "text-danger"}>
                        {value.place3}
                      </p>
                      <p
                        className={"mb-0 " + (darkTheme ? "text-white-50" : "")}
                      >
                        {value.desc3}
                      </p>
                    </div>
                  )}
                  {value.desc4 && (
                    <div>
                      <a href={value.link4} target="_blank" rel="noreferrer">
                        <h3
                          className={
                            "text-5 pt-3 school-link " +
                            (darkTheme ? "text-white" : "")
                          }
                        >
                          {value.title4}
                        </h3>
                      </a>
                      <p className={darkTheme ? "text-primary" : "text-danger"}>
                        {value.place4}
                      </p>
                      <p
                        className={"mb-0 " + (darkTheme ? "text-white-50" : "")}
                      >
                        {value.desc4}
                      </p>
                    </div>
                  )}
                  {value.desc5 && (
                    <div>
                      <a href={value.link5} target="_blank" rel="noreferrer">
                        <h3
                          className={
                            "text-5 pt-3 school-link " +
                            (darkTheme ? "text-white" : "")
                          }
                        >
                          {value.title5}
                        </h3>
                      </a>
                      <p className={darkTheme ? "text-primary" : "text-danger"}>
                        {value.place5}
                      </p>
                      <p
                        className={"mb-0 " + (darkTheme ? "text-white-50" : "")}
                      >
                        {value.desc5}
                      </p>
                    </div>
                  )}
                </div>
              ))}
          </div>
          {/* My Experience */}
          <div className="col-md-6">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              Languages, Frameworks & Tools
            </h2>
            <div
              className={
                "bg-white rounded p-4 mb-4 " +
                (darkTheme ? "bg-dark" : "bg-white border")
              }
            >
              {experienceDetails.length > 0 &&
                experienceDetails.map((value, index) => (
                  <h3
                    key={index}
                    className={"text-5 " + (darkTheme ? "text-white" : "")}
                  >
                    {value.title}
                    {value.detail && (
                      <span className="text-white-50 text-4">
                        {" - "}
                        {value.detail}
                      </span>
                    )}
                  </h3>
                ))}
            </div>
            <h2
              className={
                "text-6 fw-600 mb-4 pt-4 " + (darkTheme ? "text-white" : "")
              }
            >
              Programming Foundations & Paradigms
            </h2>
            <div
              className={
                "bg-white rounded p-4 mb-4 " +
                (darkTheme ? "bg-dark" : "bg-white border")
              }
            >
              {foundations.length > 0 &&
                foundations.map((value, index) => (
                  <h3
                    key={index}
                    className={"text-5 " + (darkTheme ? "text-white" : "")}
                  >
                    {value.title}
                    {value.detail && (
                      <span className="text-white-50 text-4">
                        {" - "}
                        {value.detail}
                      </span>
                    )}
                  </h3>
                ))}
            </div>
          </div>
        </div>
        {/* My Skills */}
        {/* <h2
          className={
            "text-6 fw-600 mt-4 mb-4 " + (darkTheme ? "text-white" : "")
          }
        >
          My Skills
        </h2>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div key={index} className="col-md-6">
                <p
                  className={
                    " fw-500 text-start mb-2 " +
                    (darkTheme ? "text-light" : "text-dark")
                  }
                >
                  {skill.name}{" "}
                  <span className="float-end">{skill.percent}%</span>
                </p>
                <div
                  className={
                    "progress progress-sm mb-4 " + (darkTheme ? "bg-dark" : "")
                  }
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div> */}
        <div className="text-center mt-5">
          <a
            className="btn btn-outline-secondary rounded-pill shadow-none"
            href="peter-ciluzzi-resume.pdf"
            target="_blank"
            rel="noreferrer"
            // download
          >
            View Resume
            {/* <span className="ms-1">
              <i className="fas fa-download" />
            </span> */}
          </a>
        </div>
      </div>
    </section>
  );
};

export default Resume;
