import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";
const Portfolio = ({ classicHeader, darkTheme }) => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();

  const filters = {
    APPLICATIONS: "Applications",
    WEBSITES: "Websites",
  };

  const projectsData = [
    {
      title: "Gigpromoter",
      category: "Production Web Application",
      projectInfo:
        "Based on my experiences as a performer, I created Gigpromoter - a Node/Express application that helps new artists learn how to manage live events.",
      projectInfo2:
        "Users are first guided through a series of tutorials on event booking and promotion. A form is then completed for each event being planned to keep track of publicity resources and other event specifics. Once an event has been saved, an automated promotional calendar is created and users have the option of receiving weekly reminder emails that outline promotional and other tasks.",
      projectInfo3:
        " The process of planning, building and deploying a web application required that I find solutions to a wide range of engineering challenges, including:",
      projectInfo4:
        "- Developed and built an event management application for independent artists using Node, Express, and MongoDB",
      projectInfo5:
        "- Created a framework that teaches new performers how to book and promote live events, saving them the 1-2 years it takes to assemble a workflow through their own trial and error",
      projectInfo6:
        "- Complied to best practices for on-page SEO by strategically placing keywords on landing page, which resulted in Gigpromoter ranking in the top 10 Google search results for \"concert promotion software\"",
      projectInfo7:
        "- Collaborated with another engineer to build out automated reminder email functionality using AgendaJS & the Sendgrid API to execute jobs scheduled with CRON expressions",
      projectInfo8:
        "- Built event creation forms that upon submission are stored as MongoDB models; then rendered on the server with ExpressJS before being populated in the client view using EJS templating",
      projectInfo9:
        "- Leveraged PassportJS to authenticate users, then created Express middleware to manage user authorization by checking a value that is stored in the MongoDB user model when a new account is created",
      projectInfo10:
        "- Created a payment system using the Stripe billing API for clients wanting to use premium features; once user payment has been processed that value is also stored in the user model and checked against Express middleware to ensure only paid users are authorized to create events",
      projectInfo11:
        "- Implemented CSP directives and protection against data-injection and XSS attacks using Joi and Helmet",
      projectInfo12:
        "- Created remote test environments by deploying staging & production versions to Heroku, which allowed further testing to be done outside the local development environment",
      projectInfo13:
        "Files that constitute automated email functionality are included at the Github link below.",
      client: "",
      technologies:
        "Node, Express, EJS, Bootstrap, Mongo, Mongoose, Passport, Agenda, CRON, MomentJS, Sendgrid API, Stripe API, Joi, Helmet",
      industry: "Performing Arts, Career Development, Event Promotion",
      url: {
        name: "www.gigpromoter.com",
        link: "https://www.gigpromoter.com",
      },
      github: {
        name: "Selected files shared at github.com/6tring/automated-emails",
        link: "https://github.com/6tring/automated-emails",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/gigpromoter",
        twitter: "http://www.twitter.com/gigpromoter",
        instagram: "http://www.instagram.com/gigpromoter_com",
        mail: "mailto:info@gigpromoter.com",
      },
      thumbImage: "images/gp-screenshot-2023.jpg",
      sliderImages: [
        "images/screenshot-gp-app-1.jpg",
        "images/screenshot-gp-app-2.jpg",
      ],
      categories: [filters.APPLICATIONS, filters.WEBSITES],
    },
    {
      title: "Flyer Distribution App",
      category: "React/Redux SPA",
      projectInfo:
        "React app that displays a map with recommendations for places to hang concert flyers based on an inputted location. Users enter an event city and state then select from location types such as Coffee Shops or Bookstores. Those values are then dispatched with action types to a reducer function before being sent to a Redux store as strings, then concatenated and passed to a Google Maps Embed API call that renders a map with search results based on the user's selections",
      client: "",
      technologies:
        "React, Redux, React-Redux, Redux-Toolkit, Material UI, Vite, Google Maps API",
      industry: "Performing Arts, Event Promotion, Street Team",
      url: {
        name: "flyering-locator-app.peterciluzzi.dev",
        link: "https://flyering-locator-app.peterciluzzi.dev",
      },
      github: {
        name: "github.com/6tring/flyering-locator-app",
        link: "https://github.com/6tring/flyering-locator-app",
      },
      socialLinks: {
        facebook: "http://www.facebook.com",
        twitter: "http://www.twitter.com",
        instagram: "http://www.instagram.com/",
        mail: "mailto:info@peterciluzzi.dev",
      },
      thumbImage: "images/screenshot-flyering-app-1.jpg",
      sliderImages: [
        "images/screenshot-flyering-app-2.jpg",
        "images/screenshot-flyering-app-3.jpg",
      ],
      categories: [filters.APPLICATIONS, filters.WEBSITES],
    },
    {
      title: "Guitar Chord Finder App",
      category: "React SPA",
      projectInfo:
        "Users have the option of selecting chord key, quality, extension and bass values from dropdowns that are dispatched with action types to a reducer function before being stored in context as strings, then concatenated and passed to an Uberchord API call that renders chord diagrams based on the user's selections",
      client: "",
      technologies:
        "React, React-Bootstrap, create-react-app, Uberchord API",
      industry: "Music Theory, Guitar Technique, Music Education",
      url: {
        name: "chord-finder-app.peterciluzzi.dev",
        link: "https://chord-finder-app.peterciluzzi.dev",
      },
      github: {
        name: "github.com/6tring/chord-finder-app",
        link: "https://github.com/6tring/chord-finder-app",
      },
      socialLinks: {
        facebook: "http://www.facebook.com",
        twitter: "http://www.twitter.com",
        instagram: "http://www.instagram.com/",
        mail: "mailto:info@peterciluzzi.dev",
      },
      thumbImage: "images/screenshot-chord-app-1.jpg",
      sliderImages: [
        "images/screenshot-chord-app-2.jpg",
        "images/screenshot-chord-app-3.jpg",
      ],
      categories: [filters.APPLICATIONS, filters.WEBSITES],
    },
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, []);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  return (
    <>
      <section
        id="portfolio"
        className={"section " + (darkTheme ? "bg-dark" : "bg-light")}
      >
        <div className={"container " + (classicHeader ? "mb-4" : "px-lg-5")}>
          {/* Heading */}
          <div className="position-relative d-flex text-center mb-5">
            <h2
              className={
                "text-24  text-uppercase fw-600 w-100 mb-0 " +
                (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
              }
            >
              Portfolio
            </h2>
            <p
              className={
                "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
                (darkTheme ? "text-white" : "text-dark")
              }
            >
              {" "}
              My Work
              <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
            </p>
          </div>
          {/* Heading end*/}
          {/* Filter Menu */}
          {/* <ul
            className={
              "portfolio-menu nav nav-tabs justify-content-center border-bottom-0 mb-5 " +
              (darkTheme ? "nav-light" : "")
            }
          >
            <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "*" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                All
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " +
                    (filterKey === filters[oneKey] ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(filters[oneKey])}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul> */}
          {/* Filter Menu end */}
          <div className="portfolio popup-ajax-gallery">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      project.categories.join(" ")
                    }
                    key={index}
                  >
                    <div className="portfolio-box rounded">
                      <div className="portfolio-img rounded app-screenshot">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div className="portfolio-overlay">
                          <a
                            className="popup-ajax stretched-link"
                            href=""
                            onClick={() => {
                              setSelectedProjectDetails(projectsData[index]);
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          />
                          <div className="portfolio-overlay-details">
                            <h5 className="text-white fw-400">
                              {project.title}
                            </h5>
                            <span className="text-light">
                              {project.category}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      <div className="project-details-modal">
        {/* Modal */}
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          darkTheme={darkTheme}
        ></ProjectDetailsModal>
      </div>
    </>
  );
};

export default Portfolio;
