import React from "react";
import Typewriter from "typewriter-effect";
// import videobg from "../videos/home.mp4";
import { Tooltip } from "./Tooltip";

const Home = ({ classicHeader, darkTheme, handleNavClick }) => {
  return (
    <section id="home">
      <div className="hero-wrap">
        <div className="hero-mask opacity-9 bg-dark" />

        {/* ---------------image background------------------ */}
        {/* <div
          className="hero-bg parallax code-bg-mobile"
          style={{ backgroundImage: 'url("images/code-mobile.jpg")' }}
        ></div> */}
        <div
          className="hero-bg parallax code-bg"
          style={{ backgroundImage: 'url("images/code.jpg")' }}
        ></div>

        {/* -------------------video background---------------------- */}

        {/* <div className="player hero-bg parallax">
          <video
            src={videobg}
            autoPlay
            muted
            loop
            style={{ width: "100%", height: "100vh", objectFit: "cover" }}
          ></video>
        </div> */}

        <div className="hero-content section d-flex min-vh-100">
          <div className="container my-auto">
            <div className="row">
              <div className="col-12 text-center">
                <p className="text-7 fw-500 head-yellow-text mb-3">
                  peterciluzzi.dev
                </p>
                <h2 className="text-11 fw-600 text-white mb-3">
                  <Typewriter
                    options={{
                      strings: [
                        "Web App Developer.",
                        "Software Engineer.",
                        "JavaScript Developer.",
                        "React Developer.",
                        "MERN Stack Developer.",
                        "Full Stack Developer.",
                      ],
                      autoStart: true,
                      loop: true,
                    }}
                  />
                </h2>
                <p className="text-5 text-light mb-3">
                  Based in Boston, Massachusetts
                </p>
                <div className="col-auto d-flex justify-content-center mb-4">
                  <ul className="social-icons social-icons-light">
                    <li className="social-icons-linkedin">
                      <Tooltip text="Linkedin" placement="top">
                        <a
                          href="http://www.linkedin.com/in/peterciluzzi"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fab fa-linkedin" />
                        </a>
                      </Tooltip>
                    </li>
                    <li className="social-icons-github">
                      <Tooltip text="Github" placement="top">
                        <a
                          href="http://www.github.com/6tring"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fab fa-github" />
                        </a>
                      </Tooltip>
                    </li>
                    <li className="social-icons-twitter">
                      <Tooltip text="Twitter" placement="top">
                        <a
                          href="http://www.twitter.com/peterciluzzi"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fab fa-twitter" />
                        </a>
                      </Tooltip>
                    </li>
                    <li className="social-icons-facebook">
                      <Tooltip text="Facebook" placement="top">
                        <a
                          href="http://www.facebook.com/peter.ciluzzi.1"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fab fa-facebook-f" />
                        </a>
                      </Tooltip>
                    </li>
                  </ul>
                </div>
                {/* <a
                  href="#contact"
                  className="btn btn-outline-primary rounded-pill shadow-none smooth-scroll mt-2"
                  onClick={(e) => {
                    e.preventDefault();
                    handleNavClick("contact");
                  }}
                >
                  Get in Touch
                </a> */}
              <a
                href="peter-ciluzzi-resume.pdf"
                // download
                target="_blank"
                rel="noreferrer"
                className="btn btn-outline-primary rounded-pill"
              >
                View Resume
              </a>
              </div>
            </div>
          </div>
          <a
            href="#about"
            className="scroll-down-arrow text-white smooth-scroll"
            onClick={(e) => {
              e.preventDefault();
              handleNavClick("about");
            }}
          >
            <span className="animated">
              <i className="fa fa-chevron-down" />
            </span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Home;
